
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { IdProvider } from '@radix-ui/react-id'
import { fbVisit, trackPageView } from 'api/analytics/analytics'
import { ShoppingCartSidebarProvider } from 'contexts/ShoppingCartSidebarContext'
import { ToastProvider } from 'contexts/ToastContext'
import { usePersistLocaleCookie } from 'hooks/usePersistedLocale'
import { useLocationSearch } from 'hooks/useRouter'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useRef, useState } from 'react'
import { QueryClient, QueryClientProvider, setLogger } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate } from 'react-query/hydration'
import { CookieKeys, getCookie, removeCookie, getVidCookie, setVidCookie } from 'services/Cookies'
import { Storage, StorageKeys } from 'services/Storage'
import { globalStyles } from 'stitches.config'
import { Api, Errors } from 'types/Api'
import { DiscountBanner } from '../components/DiscountBanner'
import { SessionKeys, SessionStorage } from '../services/Session'
import { ENV } from '../constants/EnvHelp'
import { DiscountCodeProvider } from '../contexts/DiscountContext'

function MyApp({ Component, pageProps }: AppProps) {
  const queryClientRef = useRef<QueryClient>()
  const router = useRouter()

  useEffect(() => {
    const _listener = (event: any) => {
      if ((document.activeElement as HTMLInputElement).type === 'number') {
        ;(document.activeElement as HTMLInputElement).blur()
      }
    }

    document.addEventListener('wheel', _listener)

    return () => document.removeEventListener('wheel', _listener)
  }, [])

  globalStyles()
  usePersistLocaleCookie()

  if (!queryClientRef.current) {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          onError: (err) => {
            const error = err as Api.Error
            if (
              (error &&
                error.statusCode === 401 &&
                (error.message === Errors.JWT_EXPIRED ||
                  error.message === Errors.INVALID_SIGNATURE)) ||
              (error && error.statusCode === 404 && error.code === Errors.CLIENT_NOT_FOUND)
            ) {
              removeCookie(CookieKeys.SESSION)
              router.replace('/login')
            }
          },
        },
        mutations: {
          onError: (err) => {
            const error = err as Api.Error
            if (
              (error &&
                error.statusCode === 401 &&
                (error.message === Errors.JWT_EXPIRED ||
                  error.message === Errors.INVALID_SIGNATURE)) ||
              (error.statusCode === 404 && error.code === Errors.CLIENT_NOT_FOUND)
            ) {
              removeCookie(CookieKeys.SESSION)
              router.replace('/login')
            }
          },
        },
      },
    })

    queryClientRef.current = queryClient
  }

  setLogger({
    log: () => {},
    warn: () => {},
    error: () => {},
  })

  //search for query params
  const search = useLocationSearch()
  const [ranUTMS, setRanUTMS] = useState(false)
  const [sessionId, setsessionId] = useState(false)
  const [promoCode, setPromoCode] = useState('')

  const defaultCode = '20WAG'

  //temporary default promotion for banner: FTB receives free tote and 30 % first box
  const promotionCode = 'TOTE'
  // TODO: Test on staging Friday Feb 7th
  const startDate = ENV.isProd ? new Date(2025, 1, 10, 0, 0, 0) : new Date(2025, 1, 7, 0, 0, 0) // Feb 10, 2025 00:00 local time
  const endDate = new Date(2025, 1, 19, 23, 59, 59) // Feb 19, 2025 23:59:59 local time

  useEffect(() => {
    
    if (existsInParamsCheck('utm_source') && !SessionStorage().getItem(SessionKeys.UTM_SOURCE))
      sessionStoreUtmSourceIn()
    if (existsInParamsCheck('utm_') && !Storage.get(StorageKeys.UTM_KEYS)) {
      const utmParamValues = concatElementsFromParams('utm_')
      Storage.set(StorageKeys.UTM_KEYS, utmParamValues)
    }

    //checks if the current date is within the promotion period and sets the promo code accordingly
    const setPromoCodeBasedOnDate = (now: Date) => {
      const isPromoPeriod = now >= startDate && now <= endDate
      return isPromoPeriod ? promotionCode : defaultCode
    }

    //checks if a promo code exists in the query params (url) and sets the promo code to what is there
    if (existsInParamsCheck('promocode')) {
      let pc = popElementFromParams('promoCode')
      if (pc) {
        Storage.set(StorageKeys.PROMO_CODE, pc)
      }
    } else {
      //if no promo code in query params, check if there is one in storage
      const currentCode = Storage.get(StorageKeys.PROMO_CODE)
      const now = new Date()

      //if no promo code in storage, check if the current date is within the promotion period and set the promo code accordingly
      if (
        !currentCode ||
        (currentCode === promotionCode && (now <= startDate || now >= endDate)) ||
        (currentCode === defaultCode && now >= startDate && now <= endDate)
      ) {
        Storage.set(StorageKeys.PROMO_CODE, setPromoCodeBasedOnDate(now))
      }
    }
    if (existsInParamsCheck('irclickid') && !Storage.get(StorageKeys.IMPACT_RADIUS_CLICK_ID)) {
      let ircId = popElementFromParams('irclickid')
      if (ircId) {
        Storage.set(StorageKeys.IMPACT_RADIUS_CLICK_ID, ircId)
      }
    }
    if (
      !Storage.get(StorageKeys.VERSION_FLAG) ||
      typeof Storage.get(StorageKeys.VERSION_FLAG) !== 'object' ||
      illegalFlagFound(Storage.get(StorageKeys.VERSION_FLAG)) ||
      requiredTestMissing(Storage.get(StorageKeys.VERSION_FLAG))
    ) {
      Storage.set(StorageKeys.APPLE_PAY_ELIGIBLE, window.ApplePaySession ? 'true' : 'false') //never remove this

      const smsLogin = Math.random() < 0.5 ? '07_24_sms_login_control' : '07_24_sms_login_v1'

      Storage.set(StorageKeys.VERSION_FLAG, {
        smsLogin: ENV.getVersionFlags()?.smsLogin || smsLogin,
      })
    }

    if (existsInParamsCheck('test') && existsInParamsCheck('variant')) {
      const key = popElementFromParams('test')
      const value = popElementFromParams('variant')
      const obj = {} as any
      if (key) obj[key] = value
      Storage.set(StorageKeys.VERSION_FLAG, obj)
    }

    // utilized to set cookies for server side variables in testing. keeping for reference
    // if (Storage.get(StorageKeys.VERSION_FLAG)) {
    //   const recipeSelectorStored = Storage.get(StorageKeys.VERSION_FLAG)?.recipeSelector
    //   setCookie(CookieKeys.RECIPE_SELECTOR, recipeSelectorStored)
    // }

    if (window) {
      window.onload = (e: any) => {
        Storage.set(StorageKeys.APPLE_PAY_ELIGIBLE, window.ApplePaySession ? 'true' : 'false')
      }
    }

    setRanUTMS(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!ranUTMS) return

    const cookieVID = getVidCookie(CookieKeys._VID)

    // If cookie exists, decode and validate it
    if (cookieVID) {
      try {
        const decodedData = JSON.parse(decodeURIComponent(cookieVID))

        // Check if the decoded cookie contains valid value and expiry
        if (decodedData.value && decodedData.expiry) {
          const now = Date.now()
          if (decodedData.expiry > now) {
            setsessionId(true)
            return
          }
        }
      } catch {
        // If parsing fails, fall through to creating new VID
      }
    }

    // Create new VID if no valid cookie exists or if the cookie has expired
    const visitorId = Number.parseInt(Date.now() + Math.floor(Math.random() * 10000).toString())
    const vidData = {
      value: visitorId,
      expiry: new Date(Date.now() + 6 * 60 * 60 * 1000).getTime(), // Expiry set for 6 hours from now
    }

    // Set the cookie with the domain specified to allow access across subdomains
    setVidCookie(CookieKeys._VID, JSON.stringify(vidData), '.wefeedraw.com') // Cookie valid for 1 day (24 hours)
    setsessionId(true)
  }, [ranUTMS])

  useEffect(() => {
    if (!window || !sessionId) return
    const sessionStorage = SessionStorage()
    window.onload = (e: any) => {
      const gaCookie = getCookie(CookieKeys.GA)
      if (gaCookie) sessionStorage.setItem(SessionKeys.GA, gaCookie)
      Storage.set(StorageKeys.APPLE_PAY_ELIGIBLE, window.ApplePaySession ? 'true' : 'false')
    }
    ;(async () => {
      await fbVisit()
      trackPageView({
        contentName: router.pathname,
        contentCategory: 'page_view',
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId])

  const illegalFlagFound = (obj: any): boolean => {
    if (!obj) return false
    const allowedFlags = ['07_24_sms_login_control', '07_24_sms_login_v1']

    const keys = Object.keys(obj)
    const filter = keys.filter((e) => {
      return !allowedFlags.includes(obj[e])
    })

    return filter.length > 0
  }

  const requiredTestMissing = (obj: any): boolean => {
    if (!obj) return false
    const requiredFlags = ['smsLogin']

    const keys = Object.keys(obj)
    const filter = requiredFlags.filter((e) => {
      return !keys.includes(e)
    })
    return filter.length > 0
  }

  const popElementFromParams = (name: string) => {
    let arr = search && search.replace('?', '').split('&')
    if (!arr) return
    let pc
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i].includes(name)) continue
      pc = arr[i].split('=').pop()
      break
    }
    return pc
  }

  const concatElementsFromParams = (name: string) => {
    let arr = search && search.toLowerCase().replace('?', '').split('&')
    if (!arr) return
    let delim = ''
    let paramValues = ''
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i].includes(name)) continue
      paramValues += delim + arr[i]
      delim = '&'
    }
    return paramValues
  }

  const existsInParamsCheck = (name: string) => {
    return (
      search &&
      typeof search === 'string' &&
      search.length > 0 &&
      search.toLowerCase().includes(name)
    )
  }

  const olderThan24Hours = (now: number, then: number) => {
    const msBetweenDates = Math.abs(then - now)
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000)
    return hoursBetweenDates > 24
  }

  const sessionStoreUtmSourceIn = () => {
    const allowedSources = ['future_subscription_reminder', 'subscription_renewal_reminder']
    let arr = search && search.toLowerCase().replace('?', '').split('&')
    if (!arr) return
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i].includes('utm_source')) continue
      const value = arr[i].split('=').pop() || ''
      if (!allowedSources.includes(value)) continue
      SessionStorage().setItem(SessionKeys.UTM_SOURCE, value)
    }
  }

  if (Storage.get(StorageKeys._VID) && Storage.get(StorageKeys._VID).value) {
    trackPageView({
      contentName: router.pathname,
      contentCategory: 'page_view',
    })
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Script src="https://js.chargebee.com/v2/chargebee.js" />
      <Hydrate state={pageProps.dehydratedState}>
        <IdProvider>
          <ToastProvider>
            <ShoppingCartSidebarProvider>
              <DiscountCodeProvider>
                <DiscountBanner pathName={router.pathname} defaultCode={defaultCode} />
                <Component {...pageProps} />
              </DiscountCodeProvider>
            </ShoppingCartSidebarProvider>
          </ToastProvider>
        </IdProvider>
      </Hydrate>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  
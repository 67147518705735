// DiscountCodeContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useFetchDiscountCode } from '../api/queries/useFetchDiscountByCode'
import { Storage, StorageKeys } from '../services/Storage'
import { formatCurrencyWithoutDecimals } from '../utils/currency'

interface DiscountCodeContextProps {
  discountCodeData: any // Define the type according to your data structure
  isLoading: boolean
  error: any
  refetchDiscountCode: () => void
}

type MyComponentProps = {
  children: React.ReactNode
}

const DiscountCodeContext = createContext<DiscountCodeContextProps | undefined>(undefined)

export const DiscountCodeProvider: React.FC<MyComponentProps> = ({ children }) => {
  const [discountCodeData, setDiscountCodeData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const code = Storage.get(StorageKeys.PROMO_CODE) ?? null
  const { data, status, refetch } = useFetchDiscountCode(code, {
    enabled: !!code,
  })

  const formatForDisplay = (data: any) => {
    const discountTreatQuantity = twoTreatDiscounts.includes(code) ? 2 : 1

    const treatCodeCopy = code.toLowerCase().includes('bison')
      ? `bison treats ($${27.99 * discountTreatQuantity} value)!`
      : code.toLowerCase().includes('pork')
      ? `pork treats ($${23.99 * discountTreatQuantity} value)!`
      : undefined

    const freeCopyQuantity = discountTreatQuantity === 2 ? '2 FREE bags' : 'a FREE bag'

    let displayFreeCopy = treatCodeCopy
      ? `PLUS, ${freeCopyQuantity} of all-natural ${treatCodeCopy}`
      : '+ Free Shipping!'

    const discountType = data.type

    const displayAmount =
      discountType === 'PERCENTAGE'
        ? `${data.amount}%`
        : `${formatCurrencyWithoutDecimals(data.amount)}`

    const discountDuration = data.subscriptionChargeLimit
    const boxSpelling = data.subscriptionChargeLimit === 1 ? 'box' : 'boxes'
    const durationTotalAmount =
      discountDuration && formatCurrencyWithoutDecimals(data.amount * discountDuration)

    let displayText = ''

    if (data.channel?.toLowerCase().includes('free_referral')) {
      displayText = `Redeem Your First FREE Box`
      displayFreeCopy = '+ FREE Shipping!'
    } else if (discountType === 'PERCENTAGE') {
      displayText = `Get ${displayAmount} off your first box`
    } else if (!!discountDuration && discountType !== 'FIXED') {
      displayText = `Get ${durationTotalAmount} off! That's ${displayAmount} off ${discountDuration} ${boxSpelling}`
    } else if (!!discountDuration && discountType === 'FIXED') {
      displayText = `Get ${discountDuration} ${boxSpelling} for only ${durationTotalAmount}! That's ${displayAmount} per box`
    } else if (discountType === 'DIRECT') {
      displayText = `Get ${displayAmount} off your first box`
    } else if (discountType === 'FIXED') {
      displayText = `Introducing the ${displayAmount} Trial Box`
    }
    return { ...data, displayText, displayAmount, displayFreeCopy }
  }

  useEffect(() => {
    setIsLoading(status === 'loading')
    if (status === 'error') {
      setError(error)
    } else if (status === 'success') {
      const formattedData = formatForDisplay(data)
      setDiscountCodeData(formattedData)
    }
  }, [data, status, error])

  const refetchDiscountCode = () => {
    refetch()
  }

  return (
    <DiscountCodeContext.Provider
      value={{ discountCodeData, isLoading, error, refetchDiscountCode }}
    >
      {children}
    </DiscountCodeContext.Provider>
  )
}

export const useDiscountCode = () => {
  const context = useContext(DiscountCodeContext)
  if (!context) {
    throw new Error('useDiscountCode must be used within a DiscountCodeProvider')
  }
  return context
}

const twoTreatDiscounts = ['35PORK', '40PORK', 'PORK40']

module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['header', 'footer', 'common'],

    'rgx:/blog/*': ['blog'],

    '/': ['home', 'common'],
    '/landing': ['home'],
    '/raw-benefits': ['raw-benefits'],
    '/raw-food-subscription': ['raw-food-subscription'],

    'rgx:/user/*': ['my-account'],
    '/user': ['dashboard'],
    '/user/[clientId]': ['user-profile'],
    '/user/[clientId]/rewards': ['rewards'],
    '/user/orders/history': ['order-history'],
    '/user/orders/current': ['dashboard'],
    '/user/orders/[orderId]': ['order-detail'],
    'rgx:/user/pets/*': ['pet-detail', 'meal-plan'],
    'rgx:/user/bulk-subscriptions/*': ['bulk-subscription'],

    'rgx:/meal-plan/*': ['meal-plan', 'testimonials', 'faqs', 'checkout-form'],
    '/meal-plan/success': ['success'],
    '/meal-plan/login': ['login'],
    '/meal-plan/checkout': ['checkout-form', 'discount'],
    'rgx:/meal-plan/checkout/*': ['checkout-form', 'discount'],

    '/cart': ['cart', 'checkout-form'],
    '/cart/success': ['success'],
    '/cart/checkout/login': ['login'],
    'rgx:(/products/*|/cart)': ['e-commerce', 'e-commerce-toasts'],
    'rgx:/cart/checkout/*': ['e-commerce-checkout', 'checkout-form', 'discount'],

    '/login': ['login'],
    '/login/confirmation': ['login'],
    'rgx:/login/confirmation/*': ['login'],
    '/auth/reset-password': ['login', 'reset-password'],
    '/auth/create-password': ['login', 'create-password'],

    '/faqs': ['faqs'],
    '/privacy-policy': ['privacy-policy'],
    '/why-we-feed-raw': ['home', 'why-we-feed-raw'],
    '/terms-and-conditions': ['terms-and-conditions'],
    '/contact-us': ['contact-us'],
    '/recipes': ['recipes', 'reviews', 'recipes-overview', 'why-we-feed-raw', 'home'],
    '/recipes/freeze-dried-raw': ['recipes', 'recipes-overview', 'why-we-feed-raw'],
    'rgx:/recipes/*': ['recipes', 'reviews', 'products-v2', 'recipes-overview'],

    '/products': ['products', 'recipes', 'reviews', 'products-v2', 'home'],
    'rgx:/products/*': ['products', 'recipes', 'reviews'],

    '/reviews': ['reviews', 'recipes', 'common'],

    '/our-story': ['our-story'],
  },
  loadLocaleFrom: (lang, ns) => import(`./src/locales/${lang}/${ns}.json`).then((m) => m.default),
}
